<script setup>
import { onMounted, ref } from "vue";

defineProps({
  modelValue: {
    type: [String, Number, null],
  },
});

defineEmits(["update:modelValue"]);

const input = ref("");

onMounted(() => {
  if (input.value.hasAttribute("autofocus")) {
    input.value.focus();
  }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
  <input
    class="border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow text-md disabled:opacity-50 disabled:cursor-not-allowed"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    ref="input"
  />
</template>
